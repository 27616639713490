import React from "react";
import './Navbar.scss'

import { Link, NavLink } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Collapse,
    NavItem,
    NavbarBrand,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap";

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    closeNavbar = () => {
        this.setState({
            isOpen: false
        });
    }

    componentDidMount() {
        let headroom = new Headroom(document.querySelector("#navbar-main"), {
            tolerance: 5,
            offset : 61,
            classes: {
              initial: "animated",
              pinned: "slideDown",
              unpinned: "slideUp"
            }
        });
        // initialise
        headroom.init();
    }
    render() {
        return (
            <>
            <header className="header-global">
                <Navbar
                    className="navbar-main navbar-light header--fixed headroom"
                    expand="lg"
                    id="navbar-main"
                >
                    <Container className="container-lg p-0">
                        <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                            <img
                                alt="..."
                                src={require("../../assets/images/brand/logo.png")}
                            />
                        </NavbarBrand>
                        <button className="navbar-toggler" id="navbar_global" onClick={this.toggle}>
                            <span className="navbar-toggler-icon" />
                        </button>
                        <Collapse isOpen={this.state.isOpen} navbar toggler="#navbar_global">
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand text-center" xs="12">
                                        <Link to="/" onClick={this.closeNavbar}>
                                            <img alt="..." src={require("../../assets/images/brand/logo.png")}
                                        />
                                        </Link>
                                    </Col>
                                    <div className="collapse-close" xs="12">
                                        <button className="navbar-toggler" id="navbar_global" onClick={this.toggle}>
                                            <span />
                                            <span />
                                        </button>
                                    </div>
                                </Row>
                            </div>
                            <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto" navbar>
                                <NavItem>
                                    <NavLink className="nav-link text-light" to="/"  exact={true} onClick={this.closeNavbar}>Home</NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink to="/contact-us" className="nav-link text-light"  onClick={this.closeNavbar}>Contact</NavLink>
                                </NavItem> */}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
            </>
        );
    }
}

export default NavBar;
