import React, { Component } from 'react'
import Config from '../../config/Config'
import { Container } from 'reactstrap';
import img3 from '../../assets/images/theme/beli-di.png';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Slider from "react-slick";
import { connect } from 'react-redux';
import { getComboBrand, getComboUnit } from '../../store/actions/homeActions';
import CompareProduct from './CompareProduct';

class ProductDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentColour   : this.props.data.unit_colour[0].colour_name,
            modal_visible   : false,
        }
    }

    componentDidMount = () => {
      this.props.getComboBrand();  
    };

    updateState = (key, value) => {
        this.setState({
            [key] : value
        })
    }

    sliderSettingDefault = (data) => {
        return {
            customPaging: (i) => (
                <span>
                    <div className="dots-custom">
                        <img alt={data.unit_colour[i * 1].colour_name} src={Config.URL_IMAGE_MEDIUM + data.unit_colour[i * 1].picture} />
                    </div>
                </span>
            ),
            dots        : true,
            dotsClass   : "slick-dots slick-thumb",
            fade        : true,
            infinite    : true,
            slidesToShow: 1,
        }
    }

    dataSpesifikasi = (data) => {
        const data_spesifikasi = data !== null ? [
            { title: 'CC', value: data.cc !== null ? data.cc : "-"},
            { title: 'Diameter', value: data.diameter !== null ? data.diameter : "-"},
            { title: 'Kompresi', value: data.kompresi !== null ? data.kompresi : "-"},
            { title: 'Pelumasan', value: data.pelumasan !== null ? data.pelumasan : "-"},
            { title: 'Mesin', value: data.mesin !== null ? data.mesin : "-"},
            { title: 'Silinder', value: data.silinder !== null ? data.silinder : "-"},
            { title: 'Kapasitas BBM', value: data.volume !== null ? data.volume : "-"},
            { title: 'Daya', value: data.daya !== null ? data.daya : "-"},
            { title: 'Torsi', value: data.torsi !== null ? data.torsi : "-"},
            { title: 'starter', value: data.starter !== null ? data.starter : "-"},
            { title: 'Oli', value: data.oli !== null ? data.oli : "-"},
            { title: 'Bahan Bakar', value: data.bahan_bakar !== null ? data.bahan_bakar : "-"},
          ] : [];
        return data_spesifikasi
    } 

    render() {
        const { 
            modal_visible, 
        } = this.state;

        const data_spesifikasi_default  = this.dataSpesifikasi(this.props.data);
        
        return (
            <div className="product-wrap product-detail bg-white py-5">
                <Container className="container-lg">
                    <h2 className="product-title blue-core">{this.props.data.name}</h2>
                    <div className="product-header">
                        <div className="product-hero">
                            <Slider {...this.sliderSettingDefault(this.props.data)}>
                                {this.props.data.unit_colour.map((val, key) => (
                                    <div key={key}>
                                        <img alt={val.colour_name} src={Config.URL_IMAGE_MEDIUM + val.picture} />
                                        <div className="product-color-name">{val.colour_name}</div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="product-parent-button-compare">
                                <button onClick={ () => this.updateState("modal_visible", true) } className="product-button-compare">Bandingkan Unit</button>
                            </div>
                        </div>
                        <div className="product-description-container">
                            <div className="product-description">
                                <div className="product-description-table">
                                    <div className="product-description-row">
                                        <div className="product-description-cell">Status</div>
                                        <div className="product-description-cell">{ this.props.data.preorder_flag ? "Tersedia" : "Tidak Tersedia" }</div>
                                    </div>
                                    {
                                        this.dataSpesifikasi(this.props.data).map( (item, index) => (
                                            <div key={index} className="product-description-row">
                                                <div className="product-description-cell">{ data_spesifikasi_default[index].title }</div>
                                                <div className="product-description-cell">{ data_spesifikasi_default[index].value }</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="product-pricing">
                            <div className="product-down-payment">Rp {'dp_bayar' in this.props.data ? this.props.data.dp_bayar.toLocaleString() : ' - '}</div>
                            <div className="product-price">Rp {this.props.data.nett_price.toLocaleString()}</div>
                            <div className="product-app">
                                <img alt="Download" src={img3} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Container>

                <Modal 
                    centered    = { true } 
                    isOpen      = { modal_visible } 
                    toggle      = { () => this.updateState("modal_visible",false) }
                    className   = "product-container-modal">
                    <ModalHeader className="container-header-modal" toggle={ () => this.updateState("modal_visible",false)}>
                        <span className="title-modal">Bandingkan Unit</span>
                    </ModalHeader>
                    <ModalBody className = "container-modal" >
                        <CompareProduct data = { this.props.data }/>
                    </ModalBody>
                    <ModalFooter/>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    comboBrand  : state.comboBrand,
    comboUnit   : state.comboUnit,
})

const mapDispatchToProps = (dispatch) => ({
    getComboBrand   : () => dispatch(getComboBrand()),
    getComboUnit    : (brand) => dispatch(getComboUnit(brand)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
