// let user = JSON.parse(localStorage.getItem('user'));
const initState = { isLoad: false, joinDealer: false, joinDealerAlert: '' }

const homeReducer = (state = initState, action) => {
    switch(action.type){
        case 'HOME_ERROR':
            return {
                ...state,
                isLoad: false,
            }
        case 'HOME_SUCCESS':
            return {
                ...state,
                isLoad: false,
            }
        case 'HOME_LOAD':
            return {
                ...state,
                isLoad: true
            }
        case 'JOIN_DEALER':
            return {
                isLoad: false,
                joinDealer: action.success,
                joinDealerAlert: action.msg
            }
        default:
            return state
    }
};

export default homeReducer;