import React, { Component } from 'react';
import Config from '../../config/Config'

import Slider from "react-slick";

import { connect } from 'react-redux'
import { Container, Row } from 'reactstrap';
import { getBanner } from '../../store/actions/homeActions'

class Banner extends Component {
    componentDidMount() {
        this.props.getBanner();
    }
    render() {
        const settings = {
            dots: true,
            // fade: true,
            autoplay: true,
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            centerPadding: "15%",
            // responsive: [{
            //     breakpoint: 1440,
            //     settings: {
            //         centerPadding: "200px",
            //     }
            // }, {
            //     breakpoint: 1025,
            //     settings: {
            //         centerPadding: "150px",
            //     }
            // }, {
            //     breakpoint: 641,
            //     settings: {
            //         centerPadding: "50px",
            //     }
            // }]
        };
        return this.props.banner.isLoad ? (
            <BannerLoading/>
        ) : (
            <div className="home-wrap home-banner">
                <Container className="container-lg">
                    <Row>
                        <Slider {...settings}>
                            {this.props.banner.banner.map((val, key) => {
                                return (
                                    <img key={key} alt={val.display} style={{ width: '123%' }} src={Config.URL_IMAGE_ORIGINAL + val.picture} />
                                )
                            })}
                        </Slider>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    banner: state.banner
})

const mapDispatchToProps = (dispatch) => ({
    getBanner: () => dispatch(getBanner())
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner);


export class BannerLoading extends Component {
    render() {
        return (
            <div className="home-wrap home-banner">
                <Container className="container-lg">
                    <div className="home-banner-isLoad">
                        <div className="banner-isLoad isLoad"/>
                    </div>
                </Container>
            </div>
        )
    }
}


