// let user = JSON.parse(localStorage.getItem('user'));
const initState = { isLoad: true, category: [] }

const categoryReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_CATEGORY':
            return {
                isLoad: false,
                category: action.category || [],
            }
        case 'LOAD_CATEGORY':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default categoryReducer;