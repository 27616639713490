import React, { Component } from 'react'
import { Switch, Route } from "react-router-dom";
// import { Transition, TransitionGroup } from 'react-transition-group';

// import { play, exit } from '../misc/Timeline'
import NotFound from '../misc/NotFound'

import IndexHome from "../../pages/home/IndexHome";
import IndexProduct from "../../pages/product/IndexProduct";

export default class Main extends Component {
    render() {
        return (
            <Route render={({ location }) => {
                return (
                    // <TransitionGroup component={null}>
                    //     <Transition
                    //     key={location.pathname}
                    //     appear={true}
                    //     onEnter={(node, appears) => play(node, appears)}
                    //     onExit={(node, appears) => exit(node, appears)}
                    //     timeout={{enter: 750, exit: 150}}
                    //     >
                            <Switch>
                                <Route path="/" exact component={IndexHome} />} />
                                <Route path="/:brand/:location/:unit" component={IndexProduct} />} />
                                <Route path="*" component={NotFound} />
                            </Switch>
                    //     </Transition>
                    // </TransitionGroup>
                )
            }}/>
        )
    }
}
