// let user = JSON.parse(localStorage.getItem('user'));
const initState = { isLoad: true, banner: [] }

const bannerReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_BANNER':
            return {
                isLoad: false,
                banner: action.banner || [],
            }
        case 'LOAD_BANNER':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default bannerReducer;