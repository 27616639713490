import React, { Component } from 'react';
import './Home.scss';

import Banner from './Banner';
import Brand from './Brand';
import Product from './Product';

export default class IndexHome extends Component {
    render() {
        return (
            <>
                <main id="home" ref="main">
                    <Banner />
                    <Brand param={this.props.match.params.brand} />
                    <Product />
                </main>
            </>
        )
    }
}