export const getUris = (uris) => {
    return new Promise((resolve, reject) => {
        Promise.all(uris.map(url =>
            fetch(url.uri || '', {method: url.method || 'post'}).then(resp => resp.json())
        )).then(resp => {
            resolve(resp)
        }).catch(err => {
            reject(err)
        })
    });
}

export default {
    URL                     : "https://api.portal.genesys.co.id/api/",
    URL_IMAGE_ORIGINAL      : "https://portal.genesys.co.id/image/original/",
    URL_IMAGE_LARGE         : "https://portal.genesys.co.id/image/large/",
    URL_IMAGE_MEDIUM        : "https://portal.genesys.co.id/image/medium/",
    URL_IMAGE_SMALL         : "https://portal.genesys.co.id/image/small/",
    URL_IMAGE_THUMBNAIL     : "https://portal.genesys.co.id/image/thumbnail/",
    URL_IMAGE_AGENTS        : "https://api.portal.genesys.co.id/image/thumbnail/agents/",
    URLs                    : {
        LOCATION    : 'home/groupdealer',
        BANNER      : 'data/banner',
        BRAND       : 'home/combo/brand',
        UNIT        : 'home/data/unit',
        CATEGORY    : 'home/combo/unit_category',
        ANGSURAN    : 'home/data/angsuran',
        JOIN        : 'join_dealer'
    },
    AJAX                    : {
        HEADERS: {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json'
        }
    },
    FUNC                    : {
        slugify: (text) => {
            var slugify = text.replace(/\W+/g, '-').toLowerCase();
            return slugify;
        },
        getSlugId: (slug) => {
            var res = slug.split(/[\s-]+/);
            return res[res.length-1];
        },
        ftzMoneyAbbr: function(nomer) {
            if (nomer >= 1000000000) {
                nomer = (nomer / 1000000000).toFixed(1).replace(/\.0$/, '') + 'mil';
            } else if (nomer >= 1000000) {
                nomer = (nomer / 1000000).toFixed(1).replace(/\.0$/, '') + 'jt';
            } else if (nomer >= 1000) {
                nomer = (nomer / 1000).toFixed(1).replace(/\.0$/, '') + 'rb';
            }
            return nomer;
        },
    }
}