// let user = JSON.parse(localStorage.getItem('user'));
const initState = {
    isLoad: true, unit: [], gdId: null, gdName: '', unitDisplay: [], unitFiltered: []
}

const unitReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_UNIT':
            return {
                isLoad: false,
                unit: action.unit || [],
                unitDisplay: action.unitDisplay || [],
                gdId: action.gdId || null,
                gdName: action.gdName || '',
                unitFiltered: []
            }
        case 'SET_UNIT_FILTER':
            return {
                ...state,
                unitFiltered: action.unitFiltered || []
            }
        case 'RESET_UNIT':
            return {
                isLoad: true,
                unit: [], 
                gdId: null,
                gdName: '',
                unitDisplay: [],
                unitFiltered: []
            };
        case 'LOAD_UNIT':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default unitReducer;