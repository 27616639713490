import React, { Component } from 'react'
import { connect } from 'react-redux'
import { filterChange } from '../../store/actions/homeActions';
import Loader from '../../assets/images/theme/loader.svg'
import RangeSlider from '../../components/misc/RangeSlider'

class Filter extends Component {
    handleFilter = (e) => {
        const isChecked = e.target.checked;
        this.props.filterChange(e.target.name, parseInt(e.target.value), {isChecked})
    }
    render() {
        const { category, location, filter, unit } = this.props;
        return (
            <div className="home-product-filter">
                {/* Filter Location */}
                <div className="filter-box">
                    <div className="filter-title justify-content-between">
                        Location
                        {location.isLoad && <img alt="loader" height={23} src={Loader} />}
                    </div>
                        {
                            location.location.length > 0  ? (
                                <div className="form-group m-0">
                                    <select
                                        className="form-control"
                                        id={`selectLocation`}
                                        name="group_dealer_area_id"
                                        onChange={this.handleFilter}
                                        defaultValue={parseInt(filter.location)}
                                    >
                                        {location.location.map((val, key) => {
                                            return (
                                                <option key={key} value={val.id}>{val.display}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            ) : location.isLoad ? (
                                <h6 className="text-center">&nbsp;</h6>
                            ) : (
                                <h6 className="text-center">Lokasi tidak ditemukan.</h6>
                            )
                        }
                </div>
                
                {/* Filter Location */}
                <div className="filter-box">
                    <div className="filter-title justify-content-between">
                        Category
                        {category.isLoad && <img alt="loader" height={23} src={Loader} />}
                    </div>
                    {
                        category.category.length > 0 ? category.category.map((val, key) => {
                            return (
                                <div key={key} className="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id={`customCheck${key}`}
                                        type="checkbox"
                                        onChange={this.handleFilter}
                                        value={val.id}
                                        name="unit_category"
                                    />
                                    <label className="custom-control-label" htmlFor={`customCheck${key}`}>
                                        {val.display}
                                    </label>
                                </div>
                            )
                        }) : category.isLoad ? (
                            <h6 className="text-center">&nbsp;</h6>
                        ) : (
                            <h6 className="text-center">Kategori tidak ditemukan.</h6>
                        )
                    }
                </div>

                {/* Filter Price */}
                <div className="filter-box filter-price">
                    <div className="filter-title justify-content-between">
                        Price Range
                        {unit.isLoad && <img alt="loader" height={23} src={Loader} />}
                    </div>
                    {!unit.isLoad && <RangeSlider
                        price_min={filter.price_min}
                        price_max={filter.price_max} />
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    unit: state.unit,
    filter: state.filter,
    category: state.category,
    location: state.location

})

const mapDispatchToProps = (dispatch) => ({
    filterChange: (name, value, eOpts) => dispatch(filterChange(name, value, eOpts))
})

export default connect(mapStateToProps, mapDispatchToProps)(Filter)