import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Config from '../../config/Config'
import Slider from "react-slick";

import { connect } from 'react-redux'
import { Container } from 'reactstrap';
import { getBrand, onChooseBrand } from '../../store/actions/homeActions'

class Brand extends Component {
    componentDidMount() {
        this.props.getBrand();
    }
    onClick = (e) => {
        e.preventDefault();
        this.props.onChooseBrand(parseInt(e.currentTarget.dataset.id))
    }

    render() {
        const settings = {
            className: "brand-list",
            autoplay: false,
            centerMode: false,
            infinite: false,
            speed: 500,
            swipe: false,
            swipeToSlide: true,
            variableWidth: true,
            // centerPadding: "15%",
            responsive: [{
                breakpoint: 480,
                settings: {
                    swipe: true,
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        }
        return (
            <div className="home-wrap home-brand">
                <Container className="box-shadow py-2 container-lg bg-white">
                    <div className="brand-list">
                        <div className="big-title">
                            Brand
                        </div>
                        {/* <NavLink to={}></NavLink> */}
                        {
                            this.props.brand.isLoad ? (
                              <BrandLoading/>
                            ) : (
                                <Slider {...settings}>
                                    {this.props.brand.brand.map((val, key) => {
                                        return (
                                            <Link data-id={val.id} onClick={this.onClick} key={val.id} to={`/${Config.FUNC.slugify(val.display+'-'+val.id)}`}>
                                                <div className={`list-group-item-action list-group-item ${this.props.filter.brand === val.id ? 'active' : ''}`}>
                                                    <div className='brand-image' style={{backgroundImage: `url(${Config.URL_IMAGE_ORIGINAL + val.logo})` }}></div>
                                                    {val.display}
                                                    <i className="ml-2 fa fa-arrow-circle-right"></i>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </Slider>
                            )
                        }
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    brand: state.brand,
    filter: state.filter
})

const mapDispatchToProps = (dispatch) => ({
    getBrand: () => dispatch(getBrand()),
    onChooseBrand: (id_brand) => dispatch(onChooseBrand(id_brand))
})

export default connect(mapStateToProps, mapDispatchToProps)(Brand);

export class BrandLoading extends Component {
    render() {
        const data_length = [1,2,3,4,5];
        return (
            <div className="container-brand-isLoad">
            { data_length.map((item, index) => (
                <div key = {index} className={`home-brand-isLoad isLoad`}>
                    <div className='brand-isLoad'/>
                </div>
            ))}
            </div>
        )
    }
}
