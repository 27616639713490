import { combineReducers } from 'redux'
import homeReducer from './homeReducer'
import bannerReducer from './bannerReducer'
import brandReducer from './brandReducer'
import unitReducer from './unitReducer'
import filterReducer from './filterReducer'
import categoryReducer from './categoryReducer'
import locationReducer from './locationReducer'
import angsuranReducer from './angsuranReducer'
import comboBrandReducer from './comboBrandReducer'
import comboUnitReducer from './comboUnitReducer';

const rootReducer = combineReducers({
    home: homeReducer,
    banner: bannerReducer,
    brand: brandReducer,
    unit: unitReducer,
    category: categoryReducer,
    location: locationReducer,
    filter: filterReducer,
    angsuran: angsuranReducer,
    comboBrand : comboBrandReducer,
    comboUnit : comboUnitReducer,
});

export default rootReducer