import React, { Component } from 'react'
import Slider from "react-slick";
import Select from 'react-select';
import { connect } from 'react-redux';
import classnames from "classnames";

import Config from '../../config/Config';
import { getComboBrand, getComboUnit } from '../../store/actions/homeActions';

class CompareProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id_brand_1              : null,
      show_brand_1            : false,
      display_brand_1         : "",
      id_unit_1               : null,
      show_unit_1             : false,
      display_unit_1          : "",
      data_selected_1         : null,
      
      id_brand_2              : null,
      show_brand_2            : false,
      display_brand_2         : "",
      id_unit_2               : null,
      show_unit_2             : false,
      display_unit_2          : "",
      data_selected_2         : null,
      
      id_brand_3              : null,
      show_brand_3            : false,
      display_brand_3         : "",
      id_unit_3               : null,
      show_unit_3             : false,
      display_unit_3          : "",
      data_selected_3         : null,
    }
  }

  
  componentWillMount = () => {
    this.props.getComboBrand();  
    this.showModalCompare()
  };

  updateState = (key, value) => {
    this.setState({
        [key] : value
    })
}

  sliderSettingDefault = (data) => {
      return {
          customPaging: (i) => (
              <span>
                  <div className="dots-custom">
                      <img alt={data.unit_colour[i * 1].colour_name} src={Config.URL_IMAGE_MEDIUM + data.unit_colour[i * 1].picture} />
                  </div>
              </span>
          ),
          dots        : true,
          dotsClass   : "slick-dots slick-thumb",
          fade        : true,
          infinite    : true,
          slidesToShow: 1,
      }
  }

  setSelectedCombo = (item, type) => {
      if(type === "brand_1"){
          this.setState({
              show_brand_1    : false,
              show_unit_1     : item !== null ? true : false,
              id_unit_1       : null,
              display_unit_1  : "",
              data_selected_1 : null,
              id_brand_1      : item !== null ? item.value : null, 
              display_brand_1 : item !== null ? { value : item.value, label : item.label  } : "",
          });
          if(item !== null) {
              this.combo_unit_1.focus();
              this.props.getComboUnit(item.value)
          }
      }else if(type === "unit_1"){
          this.setState({
              show_unit_1     : false,
              id_unit_1       : item !== null ? item.value : null, 
              display_unit_1  : item !== null ? { value : item.value, label : item.label  } : "",
              data_selected_1 : item !== null ? item.data  : null,
          })
      }else if(type === "brand_2"){
          this.setState({
              show_brand_2    : false,
              show_unit_2     : item !== null ? true : false,
              id_unit_2       : null,
              display_unit_2  : "",
              data_selected_2 : null,
              id_brand_2      : item !== null ? item.value : null, 
              display_brand_2 : item !== null ? { value : item.value, label : item.label  } : "",
          });
          if(item !== null) {
              this.combo_unit_2.focus();
              this.props.getComboUnit(item.value)
          }
      }else if(type === "unit_2"){
          this.setState({
              show_unit_2             : false,
              id_unit_2               : item !== null ? item.value : null, 
              display_unit_2          : item !== null ? { value : item.value, label : item.label  } : "",
              data_selected_2         : item !== null ? item.data  : null,
              data_image_selected_2   : item !== null ? this.sliderSettingDefault(item.data) : null
          })
      }else if(type === "brand_3"){
          this.setState({
              show_brand_3    : false,
              show_unit_3     : item !== null ? true : false,
              id_unit_3       : null,
              display_unit_3  : "",
              data_selected_3 : null,
              id_brand_3      : item !== null ? item.value : null, 
              display_brand_3 : item !== null ? { value : item.value, label : item.label  } : "",
          });
          if(item !== null) {
              this.combo_unit_3.focus();
              this.props.getComboUnit(item.value)
          }
      }else if(type === "unit_3"){
          this.setState({
              show_unit_3     : false,
              id_unit_3       : item !== null ? item.value : null, 
              display_unit_3  : item !== null ? { value : item.value, label : item.label  } : "",
              data_selected_3 : item !== null ? item.data  : null,
          })
      }
  }

  showModalCompare = () => {
      const { data } = this.props
      this.setState({
        id_brand_1      : data.master_brand_id,
        display_brand_1 :  { value : data.master_brand_id, label : data.brand  },
        id_unit_1       : data.id,
        display_unit_1  : { value : data.id, label : data.name  },
        data_selected_1 : data,
      })
  }

  render() {
    const { 
      id_brand_1, 
      show_brand_1, 
      display_brand_1,
      id_unit_1,
      show_unit_1,
      display_unit_1,
      data_selected_1,
      
      id_brand_2, 
      show_brand_2, 
      display_brand_2,
      id_unit_2,
      show_unit_2,
      display_unit_2,
      data_selected_2,
      
      id_brand_3, 
      show_brand_3, 
      display_brand_3,
      id_unit_3,
      show_unit_3,
      display_unit_3,
      data_selected_3,
  } = this.state;

  const data_spesifikasi = [
    { title: 'CC',            value_1: data_selected_1 !== null ? data_selected_1.cc !== null ? data_selected_1.cc : '-' : '-',                   value_2: data_selected_2 !== null ? data_selected_2.cc !== null ? data_selected_2.cc : '-' : '-',                   value_3: data_selected_3 !== null ? data_selected_3.cc !== null ? data_selected_3.cc : '-' : '-'                  },
    { title: 'Diameter',      value_1: data_selected_1 !== null ? data_selected_1.diameter !== null ? data_selected_1.diameter : '-' : '-',       value_2: data_selected_2 !== null ? data_selected_2.diameter !== null ? data_selected_2.diameter : '-' : '-',       value_3: data_selected_3 !== null ? data_selected_3.diameter !== null ? data_selected_3.diameter : '-' : '-'      },
    { title: 'Kompresi',      value_1: data_selected_1 !== null ? data_selected_1.kompresi !== null ? data_selected_1.kompresi : '-' : '-',       value_2: data_selected_2 !== null ? data_selected_2.kompresi !== null ? data_selected_2.kompresi : '-' : '-',       value_3: data_selected_3 !== null ? data_selected_3.kompresi !== null ? data_selected_3.kompresi : '-' : '-'      },
    { title: 'Pelumasan',     value_1: data_selected_1 !== null ? data_selected_1.pelumasan !== null ? data_selected_1.pelumasan : '-' : '-',     value_2: data_selected_2 !== null ? data_selected_2.pelumasan !== null ? data_selected_2.pelumasan : '-' : '-',     value_3: data_selected_3 !== null ? data_selected_3.pelumasan !== null ? data_selected_3.pelumasan : '-' : '-'    },
    { title: 'Mesin',         value_1: data_selected_1 !== null ? data_selected_1.mesin !== null ? data_selected_1.mesin : '-' : '-',             value_2: data_selected_2 !== null ? data_selected_2.mesin !== null ? data_selected_2.mesin : '-' : '-',             value_3: data_selected_3 !== null ? data_selected_3.mesin !== null ? data_selected_3.mesin : '-' : '-'            },
    { title: 'Silinder',      value_1: data_selected_1 !== null ? data_selected_1.silinder !== null ? data_selected_1.silinder : '-' : '-',       value_2: data_selected_2 !== null ? data_selected_2.silinder !== null ? data_selected_2.silinder : '-' : '-',       value_3: data_selected_3 !== null ? data_selected_3.silinder !== null ? data_selected_3.silinder : '-' : '-'      },
    { title: 'Kapasitas BBM', value_1: data_selected_1 !== null ? data_selected_1.volume !== null ? data_selected_1.volume : '-' : '-',           value_2: data_selected_2 !== null ? data_selected_2.volume !== null ? data_selected_2.volume : '-' : '-',           value_3: data_selected_3 !== null ? data_selected_3.volume !== null ? data_selected_3.volume : '-' : '-'          },
    { title: 'Daya',          value_1: data_selected_1 !== null ? data_selected_1.daya !== null ? data_selected_1.daya : '-' : '-',               value_2: data_selected_2 !== null ? data_selected_2.daya !== null ? data_selected_2.daya : '-' : '-',               value_3: data_selected_3 !== null ? data_selected_3.daya !== null ? data_selected_3.daya : '-' : '-'              },
    { title: 'Torsi',         value_1: data_selected_1 !== null ? data_selected_1.torsi !== null ? data_selected_1.torsi : '-' : '-',             value_2: data_selected_2 !== null ? data_selected_2.torsi !== null ? data_selected_2.torsi : '-' : '-',             value_3: data_selected_3 !== null ? data_selected_3.torsi !== null ? data_selected_3.torsi : '-' : '-'            },
    { title: 'starter',       value_1: data_selected_1 !== null ? data_selected_1.starter !== null ? data_selected_1.starter : '-' : '-',         value_2: data_selected_2 !== null ? data_selected_2.starter !== null ? data_selected_2.starter : '-' : '-',         value_3: data_selected_3 !== null ? data_selected_3.starter !== null ? data_selected_3.starter : '-' : '-'        },
    { title: 'Oli',           value_1: data_selected_1 !== null ? data_selected_1.oli !== null ? data_selected_1.oli : '-' : '-',                 value_2: data_selected_2 !== null ? data_selected_2.oli !== null ? data_selected_2.oli : '-' : '-',                 value_3: data_selected_3 !== null ? data_selected_3.oli !== null ? data_selected_3.oli : '-' : '-'                },
    { title: 'Bahan Bakar',   value_1: data_selected_1 !== null ? data_selected_1.bahan_bakar !== null ? data_selected_1.bahan_bakar : '-' : '-', value_2: data_selected_2 !== null ? data_selected_2.bahan_bakar !== null ? data_selected_2.bahan_bakar : '-' : '-', value_3: data_selected_3 !== null ? data_selected_3.bahan_bakar !== null ? data_selected_3.bahan_bakar : '-' : '-'},
  ];
    return (
      <>
        {/* SECTION COMBO */}
        <div className = "product-body-modal filter">
          {/* DATA COMBO 1 */}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
              <div className="box-combo-compare">
                  <Select
                      ref                 = { ref => { this.combo_brand_1 = ref } }
                      value               = { display_brand_1 }
                      options             = { this.props.comboBrand.brand }
                      placeholder         = "Cari Brand 1"
                      name                = "combo_brand_1"
                      className           = "basic-single"
                      isDisabled          = { false }
                      isLoading           = { this.props.comboBrand.isLoad }
                      isSearchable        = { true }
                      isClearable         = { true }          
                      menuIsOpen          = { show_brand_1 }       
                      autoFocus           = { show_brand_1 }           
                      closeMenuOnSelect   = { true }
                      blurInputOnSelect   = { true }
                      onChange            = { (item) => this.setSelectedCombo(item,"brand_1") }
                      onMenuOpen          = { () => this.updateState("show_brand_1", true) }
                      onBlur              = { () => this.updateState("show_brand_1", false) }            
                  />
              </div>
              <div className="box-combo-compare">
                  <Select
                      ref                 = { ref => { this.combo_unit_1 = ref } }
                      value               = { display_unit_1 }
                      options             = { this.props.comboUnit.unit }
                      placeholder         = "Cari Unit 1"
                      name                = "combo_unit_1"
                      className           = "basic-single"
                      isDisabled          = { false }
                      isLoading           = { this.props.comboUnit.isLoad }
                      isSearchable        = { true }
                      isClearable         = { true }
                      menuIsOpen          = { show_unit_1 }
                      autoFocus           = { show_unit_1 }
                      closeMenuOnSelect   = { true }
                      blurInputOnSelect   = { true }
                      onChange            = {  (item) => this.setSelectedCombo(item,"unit_1") }
                      onMenuOpen          = { id_brand_1 !== null ? 
                          () => { this.props.getComboUnit(id_brand_1); this.updateState("show_unit_1",true)  } : 
                          () => { this.updateState("show_brand_1",true); this.combo_brand_1.focus() } }
                      onBlur              = { () => this.updateState("show_unit_1", false) }      
                      filterOption        = { (option, text) => option.value !== id_unit_2 && option.value !== id_unit_3 ? true : false }
                  />
              </div>
            </div>
          </div>
          {/* DATA COMBO 2 */}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
              <div className="box-combo-compare">
                  <Select
                      ref                 = { ref => { this.combo_brand_2 = ref } }
                      value               = { display_brand_2 }
                      options             = { this.props.comboBrand.brand }
                      placeholder         = "Cari Brand 2"
                      name                = "combo_brand_2"
                      className           = "basic-single"
                      isDisabled          = { false }
                      isLoading           = { this.props.comboBrand.isLoad }
                      isSearchable        = { true }
                      isClearable         = { true }          
                      menuIsOpen          = { show_brand_2 }       
                      autoFocus           = { show_brand_2 }           
                      closeMenuOnSelect   = { true }
                      blurInputOnSelect   = { true }
                      onChange            = { (item) => this.setSelectedCombo(item,"brand_2") }
                      onMenuOpen          = { () => this.updateState("show_brand_2", true) }
                      onBlur              = { () => this.updateState("show_brand_2", false) }            
                  />
              </div>
              <div className="box-combo-compare">
                  <Select 
                      ref                 = { ref => { this.combo_unit_2 = ref } }
                      value               = { display_unit_2 }
                      options             = { this.props.comboUnit.unit }
                      placeholder         = "Cari Unit 2"
                      name                = "combo_unit_2"
                      className           = "basic-single"
                      isDisabled          = { false }
                      isLoading           = { this.props.comboUnit.isLoad }
                      isSearchable        = { true }
                      isClearable         = { true }
                      menuIsOpen          = { show_unit_2 }
                      autoFocus           = { show_unit_2 }
                      closeMenuOnSelect   = { true }
                      blurInputOnSelect   = { true }
                      onChange            = {  (item) => this.setSelectedCombo(item,"unit_2") }
                      onMenuOpen          = { id_brand_2 !== null ? 
                          () => { this.props.getComboUnit(id_brand_2); this.updateState("show_unit_2",true)  } : 
                          () => { this.updateState("show_brand_2",true); this.combo_brand_2.focus() } }
                      onBlur              = { () => this.updateState("show_unit_2", false) }      
                      filterOption        = { (option, text) => option.value !== id_unit_1 && option.value !== id_unit_3 ? true : false }
                  />
              </div>
            </div>
          </div>
          {/* DATA COMBO 3*/}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
              <div className="box-combo-compare">
                  <Select
                      ref                 = { ref => { this.combo_brand_3 = ref } }
                      value               = { display_brand_3 }
                      options             = { this.props.comboBrand.brand }
                      placeholder         = "Cari Brand 3"
                      name                = "combo_brand_3"
                      className           = "basic-single"
                      isDisabled          = { false }
                      isLoading           = { this.props.comboBrand.isLoad }
                      isSearchable        = { true }
                      isClearable         = { true }          
                      menuIsOpen          = { show_brand_3 }       
                      autoFocus           = { show_brand_3 }           
                      closeMenuOnSelect   = { true }
                      blurInputOnSelect   = { true }
                      onChange            = { (item) => this.setSelectedCombo(item,"brand_3") }
                      onMenuOpen          = { () => this.updateState("show_brand_3", true) }
                      onBlur              = { () => this.updateState("show_brand_3", false) }            
                  />
              </div>
              <div className="box-combo-compare">
                  <Select 
                      ref                 = { ref => { this.combo_unit_3 = ref } }
                      value               = { display_unit_3 }
                      options             = { this.props.comboUnit.unit }
                      placeholder         = "Cari Unit 3"
                      name                = "combo_unit_3"
                      className           = "basic-single"
                      isDisabled          = { false }
                      isLoading           = { this.props.comboUnit.isLoad }
                      isSearchable        = { true }
                      isClearable         = { true }
                      menuIsOpen          = { show_unit_3 }
                      autoFocus           = { show_unit_3 }
                      closeMenuOnSelect   = { true }
                      blurInputOnSelect   = { true }
                      onChange            = {  (item) => this.setSelectedCombo(item,"unit_3") }
                      onMenuOpen          = { id_brand_3 !== null ? 
                          () => { this.props.getComboUnit(id_brand_3); this.updateState("show_unit_3",true)  } : 
                          () => { this.updateState("show_brand_3",true); this.combo_brand_3.focus() } }
                      onBlur              = { () => this.updateState("show_unit_3", false) }      
                      filterOption        = { (option, text) => option.value !== id_unit_1 && option.value !== id_unit_2 ? true : false }
                  />
              </div>
            </div>
          </div>
        </div>
        
        {/* SECTION IMAGES */}
        <div className = "product-body-modal">
          {/* DATA IMAGES 1 */}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
            { data_selected_1 !== null ? ( 
              <div className="container-compare">
                <div className="product-header">
                    <div className="product-hero">
                        <Slider {...this.sliderSettingDefault(data_selected_1)}>
                            {
                                data_selected_1.unit_colour.map((val, key) => (
                                    <div key={key}>
                                        <img alt={val.colour_name} src={Config.URL_IMAGE_MEDIUM + val.picture} />
                                        <div className="product-color-name">{val.colour_name}</div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
              </div>
            ) : null }
            </div>
          </div>
          {/* DATA IMAGES 2 */}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
            { data_selected_2 !== null ? ( 
              <div className="container-compare">
                <div className="product-header">
                    <div className="product-hero">
                        <Slider {...this.sliderSettingDefault(data_selected_2)}>
                            {
                                data_selected_2.unit_colour.map((val, key) => (
                                    <div key={key}>
                                        <img alt={val.colour_name} src={Config.URL_IMAGE_MEDIUM + val.picture} />
                                        <div className="product-color-name">{val.colour_name}</div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
              </div>
            ) : null }
            </div>
          </div>
          {/* DATA IMAGES 3*/}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
            { data_selected_3 !== null ? ( 
              <div className="container-compare">
                <div className="product-header">
                    <div className="product-hero">
                        <Slider {...this.sliderSettingDefault(data_selected_3)}>
                            {
                                data_selected_3.unit_colour.map((val, key) => (
                                    <div key={key}>
                                        <img alt={val.colour_name} src={Config.URL_IMAGE_MEDIUM + val.picture} />
                                        <div className="product-color-name">{val.colour_name}</div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>
              </div>
            ) : null }
            </div>
          </div>
        </div>
        
        {/* SECTION PRICE */}
        <div className = "product-body-modal">
          {/* DATA PRICE 1 */}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
            { data_selected_1 !== null ? ( 
              <div className="container-compare">
                  <div className="product-pricing">
                      <div className="product-down-payment">Rp {'dp_bayar' in data_selected_1 ? data_selected_1.dp_bayar.toLocaleString() : ' - '}</div>
                      <div className="product-price">Rp {data_selected_1.nett_price.toLocaleString()}</div>
                  </div>
              </div>
            ) : null }
            </div>
          </div>
          {/* DATA PRICE 2 */}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
            { data_selected_2 !== null ? ( 
              <div className="container-compare">
                  <div className="product-pricing">
                      <div className="product-down-payment">Rp {'dp_bayar' in data_selected_2 ? data_selected_2.dp_bayar.toLocaleString() : ' - '}</div>
                      <div className="product-price">Rp {data_selected_2.nett_price.toLocaleString()}</div>
                  </div>
              </div>
            ) : null }
            </div>
          </div>
          {/* DATA PRICE 3*/}
          <div className="container-body-modal with-padding">
            <div className="container-compare">
            { data_selected_3 !== null ? ( 
              <div className="container-compare">
                  <div className="product-pricing">
                      <div className="product-down-payment">Rp {'dp_bayar' in data_selected_3 ? data_selected_3.dp_bayar.toLocaleString() : ' - '}</div>
                      <div className="product-price">Rp {data_selected_3.nett_price.toLocaleString()}</div>
                  </div>
              </div>
            ) : null }
            </div>
          </div>
        </div>

        {/* SECTION SPESIFIKASI */}
        {
          data_selected_1 !== null || data_selected_2 !== null || data_selected_3 !== null ? (
            <div className="container-modal-spesifikasi">
              <div className="box-title-spesifikikasi">Spesifikasi</div>
              <hr/>
              { data_spesifikasi.map( (item, index) => (
                <div key={index}className = "product-body-modal">
                  <div className="container-body-modal">
                    <div className={`item-spesifikikasi ${index+1 === data_spesifikasi.length ? 'hide-border' : ''}`}>
                      <span className="title-spesifikasi">{item.title}</span>
                      <span className="value-spesifikasi">{item.value_1}</span>
                    </div>
                  </div>
                  <div className="container-body-modal">
                    <div className={`item-spesifikikasi ${index+1 === data_spesifikasi.length ? 'hide-border' : ''}`}>
                      <span className="title-spesifikasi">{item.title}</span>
                      <span className="value-spesifikasi">{item.value_2}</span>
                    </div>
                  </div>
                  <div className="container-body-modal">
                    <div className={`item-spesifikikasi ${index+1 === data_spesifikasi.length ? 'hide-border' : ''}`}>
                      <span className="title-spesifikasi">{item.title}</span>
                      <span className="value-spesifikasi">{item.value_3}</span>
                    </div>
                  </div>
                </div>
              )) }
              </div>
          ) : null
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  comboBrand  : state.comboBrand,
  comboUnit   : state.comboUnit,
})

const mapDispatchToProps = (dispatch) => ({
  getComboBrand   : () => dispatch(getComboBrand()),
  getComboUnit    : (brand) => dispatch(getComboUnit(brand)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompareProduct)
