// let user = JSON.parse(localStorage.getItem('user'));
const initState = { isLoad: true, brand: [], selected: null }

const brandReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_BRAND':
            return {
                isLoad: false,
                brand: action.brand || [],
                selected: action.selected || null
            }
        case 'SET_BRAND_SEL':
            return {
                ...state,
                selected: action.selected || null
            }
        case 'LOAD_BRAND':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default brandReducer;