const initState = { isLoad: false, unit: [] }

const comboUnitReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_COMBO_UNIT':
            return {
                isLoad: false,
                unit: action.unit || [],
            }
        case 'LOAD_COMBO_UNIT':
            return {
                isLoad: true,
                unit : []
            }
        default:
            return state
    }
};

export default comboUnitReducer;