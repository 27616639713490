// let user = JSON.parse(localStorage.getItem('user'));
const initState = { isLoad: true, angsuran: [], top_group: [] }

const angsuranReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_ANGSURAN':
            return {
                angsuran: action.angsuran || [],
                top_group: action.top_group || [],
                isLoad: false,
            }
        case 'RESET_ANGSURAN':
            return initState
        case 'LOAD_ANGSURAN':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default angsuranReducer;