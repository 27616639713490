// let user = JSON.parse(localStorage.getItem('user'));
const initState = {
    isset: false,
    brand: 0,
    location: 0,
    category: [],
    price_min: 0,
    price_max: 0
}

const filterReducer = (state = initState, action) => {
    const { brand, location, category, isset, price_min, price_max } = action;
    switch(action.type){
        case 'SET_FILTER':
            return {
                isset: isset !== undefined ? isset : state.isset,
                brand: brand !== undefined ? brand : state.brand,
                category: category !== undefined ? category : state.category,
                location: location !== undefined ? location : state.location,
                price_min: price_min !== undefined ? price_min : state.price_min,
                price_max: price_max !== undefined ? price_max : state.price_max
            }
        default:
            return state
    }
};

export default filterReducer;