import React, { Component } from 'react'
import { filterChange } from '../../store/actions/homeActions';
import { connect } from 'react-redux'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'

class RangeSlider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            range: {
                min: 0,
                max: 0
            }
        }
    }
    componentWillMount() {
        const range = {
            min: this.props.price_min,
            max: this.props.price_max
        };
        this.setState({
            range
        })
    }
    handleChange = (e) => {
        this.props.filterChange('price_range', this.state.range)
    }
    render() {
        return (
            <>
                <InputRange
                    minValue={this.props.price_min}
                    maxValue={this.props.price_max}
                    // formatLabel={value => `Rp. ${value.toLocaleString()}`}
                    step={1000}
                    value={this.state.range}
                    onChange={value => this.setState({ range: value })}
                    onChangeComplete={value => this.handleChange()}
                />
                <h6 className="display-6 text-light d-flex justify-content-between"><span>min:</span> {this.state.range.min.toLocaleString()}</h6>
                <h6 className="display-6 text-light d-flex justify-content-between"><span>max:</span> {this.state.range.max.toLocaleString()}</h6>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    filterChange: (name, value, eOpts) => dispatch(filterChange(name, value, eOpts))
})

export default connect(null, mapDispatchToProps)(RangeSlider)