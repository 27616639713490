import React, { Component } from 'react';
import ListItem, {ListItemLoad} from './ListItem';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';

import NotFound from '../../assets/images/theme/coming_soon.png';

class List extends Component {
    render() {
        const { isLoad, unit, gdId, gdName, unitFiltered } = this.props.unit;
        const unitShown = unitFiltered.length > 0 ? unitFiltered : unit;
        return isLoad ? (
            <div className="home-product-list">
                <div className="product-list-wrap">
                    <ListItemLoad />
                    <ListItemLoad />
                    <ListItemLoad />
                    <ListItemLoad />
                    <ListItemLoad />
                </div>
            </div>
        ) : (
            <div className="home-product-list">
                <div className="product-list-wrap">
                    {
                        unitShown.length > 0 ? unitShown.map((val, key) => {
                            return <ListItem
                                key={key}
                                id={val.id}
                                image={val.default_picture}
                                name={val.name}
                                description={val.description}
                                price={val.price}
                                brand={val.brand}
                                brandId={val.master_brand_id}
                                gdId={gdId}
                                gdName={gdName}
                            />
                        }) : (
                            <Col lg={12} className="text-center">
                                <img alt="Not Found" src={NotFound} />
                            </Col>
                        )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    unit: state.unit
})

export default connect(mapStateToProps, null)(List)