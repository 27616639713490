// let user = JSON.parse(localStorage.getItem('user'));
const initState = { isLoad: true, location: [], selected: null }

const locationReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_LOCATION':
            return {
                isLoad: false,
                location: action.location || [],
                selected: action.selected || null
            }
        case 'SET_LOCATION_SEL':
            return {
                ...state,
                selected: action.selected || null
            }
        case 'LOAD_LOCATION':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default locationReducer;