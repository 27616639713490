import React, { Component } from 'react';
// import '../styles/Main.scss';

export default class NotFound extends Component {
    render() {
        return (
            <div className="container-not-found">
                <div className="box-image-not-found">
                    <img style = {{ width : '75%'}} alt="..." src = { require("../../assets/images/no-data.png")}/>
                </div>
                <div className="box-text-not-found">
                    <h4>404 Halaman Tidak Ditemukan</h4>
                    <h5>Kembali ke <a href="/">Halaman Utama</a></h5>
                </div>
            </div>
        )
    }
}
