import React, { Component } from 'react'
import { Container, Row } from 'reactstrap';
import Filter from './Filter';
import List from './List';

export default class Product extends Component {
    render() {
        return (
            <div className="home-wrap home-product">
                <Container className="container-lg">
                    <Row>
                        <Filter />
                        <List />
                    </Row>
                </Container>                
            </div>
        )
    }
}
