import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import Config from '../../config/Config';

export default class ListItem extends Component {
    render() {
        const { image, name, price, gdId, gdName, id, brand, brandId } = this.props;
        const { slugify } = Config.FUNC
        const URL = `/${slugify(brand+'-'+brandId)}/${slugify(gdName+'-'+gdId)}/${slugify(name+'-'+id)}`;
        return (
            <div className="item-container">
                <div className="item-wrap">
                    <a href={URL}>
                        <div>
                            <div className="img-cont">
                                <img alt="All New Honda Beat Sporty CBS ISS" src={Config.URL_IMAGE_THUMBNAIL + image} />
                            </div>
                        </div>
                        <div className="item-desc-wrap">
                            <span className="item-title">{name}</span>
                            {/* <span className="item-desc">{description}</span> */}
                            <div className="item-price-wrap">
                                <span className="item-pricing">Rp {price.toLocaleString()}</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export class ListItemLoad extends Component {
    render() {
        return (
            <div className="item-container isLoad">
                <div className="item-wrap isLoad">
                    <div className="img-cont isLoad"></div>
                    <div className="item-desc-wrap">
                        <div className="item-title isLoad green"></div>
                        <div className="item-desc isLoad red"></div>
                        <div className="item-price-wrap isLoad"></div>
                    </div>
                </div>
            </div>
        )
    }
}