import React, { Component } from 'react'
import Config from '../../config/Config';
import classnames from "classnames";
import {
    Container,
    Card,
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavLink,
    NavItem
} from 'reactstrap'
import { connect } from 'react-redux';
import { getAngsuran } from '../../store/actions/homeActions';

import Slider from "react-slick";

class ProductAngsuran extends Component {
    constructor(props) {
        super(props)
        this.state = {
            iconTabs: 0,
            plainTabs: 0
        };
    }
    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };
    componentDidMount() {
        this.props.getAngsuran({
            group_dealer_area_id: this.props.groupdealer,
            master_unit_id: this.props.unit_id,
            master_leasing_id: 1
        })
    }

    render() {
        var settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        const { angsuran, top_group, isLoad } = this.props.angsuran
        return (
            <div className="product-wrap product-related py-4 bg-white">
                <Container className="container-lg">
                    <h4 className="blue-core">Angsuran</h4>
                    {
                        isLoad ? (
                            <h5 className="text-center display-6 py-5 blue-core">Loading ...</h5>
                        ) : angsuran.length === 0 ? <h5 className="text-center display-6 py-5 blue-core">Tidak Ada Angsuran</h5> : (
                            <div className="product-angsuran py-4">
                                <Container className="container-lg">
                                    <Nav
                                        className="nav-fill"
                                        id="tabs-icons-text"
                                        pills
                                        role="tablist"
                                    >
                                        {
                                            top_group.map((val, key) => {
                                                return (
                                                    <NavItem key={key}>
                                                        <NavLink
                                                            aria-selected={this.state.iconTabs === key}
                                                            className={classnames("mb-sm-3 mb-md-0 shadow-none blue-core", {
                                                                active: this.state.iconTabs === key
                                                            })}
                                                            onClick={e => this.toggleNavs(e, "iconTabs", key)}
                                                            href="#fitz"
                                                            role="tab"
                                                        >
                                                            {val}bln
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            })
                                        }
                                    </Nav>
                                    <Card className="angsuran-container">
                                        <CardBody>
                                            <TabContent activeTab={"iconTabs" + this.state.iconTabs}>
                                                {
                                                    top_group.map((val, key) => {
                                                        return (
                                                            <TabPane key={key} tabId={`iconTabs${key}`}>
                                                                <Slider {...settings}>
                                                                    {
                                                                        angsuran.filter(angs => {return angs.top === val}).map((top, keyTop) => {
                                                                            return (
                                                                                <div className="angsuran-item-container" key={keyTop}>
                                                                                    <div className="angsuran-item-wrap" key={keyTop}>
                                                                                        <div className="angsuran-top">
                                                                                            <h6>DP</h6>
                                                                                            <h6>{Config.FUNC.ftzMoneyAbbr(top.down_payment)}</h6>
                                                                                            <h6>{Config.FUNC.ftzMoneyAbbr(top.dp_bayar)}</h6>
                                                                                        </div>
                                                                                        <div className="angsuran-bottom">
                                                                                            <h6>Angsuran</h6>
                                                                                            <h6 className="font-weight-bold">Rp. {top.angsuran.toLocaleString()}</h6>
                                                                                            <h6>PER BULAN</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Slider>
                                                            </TabPane>
                                                        )
                                                    })
                                                }
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Container>
                            </div>
                        )   

                    }
                </Container>
            </div>
        ) 
    }
}

const mapStateToProps = (state) => ({
    angsuran: state.angsuran
})

const mapDispatchToProps = (dispatch) => ({
    getAngsuran: (pageParams) => dispatch(getAngsuran(pageParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductAngsuran)