import React, { Component } from 'react';

import NavBar from './components/navbar/Navbar';
import Main from './components/main/Main';
// import Footer from './components/Footer/Footer';
import ScrollTop from './components/misc/ScrollTop';


import './App.scss';

class App extends Component {
	
	componentDidMount() {
		this.setState({isLoading: false})
	}
	render() {
		return (
            <ScrollTop>
                <div className="App">
                    <NavBar />
                    <Main />
                </div>
            </ScrollTop>
        );
	}
}

export default App;
