const initState = { isLoad: false, brand: [] }

const comboBrandReducer = (state = initState, action) => {
    switch(action.type){
        case 'SET_COMBO_BRAND':
            return {
                isLoad: false,
                brand: action.brand || [],
            }
        case 'LOAD_COMBO_BRAND':
            return {
                ...state,
                isLoad: true
            }
        default:
            return state
    }
};

export default comboBrandReducer;