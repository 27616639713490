import React, { Component } from 'react';
import './Product.scss';
import { connect } from 'react-redux';

import { getDataUnit } from '../../store/actions/homeActions';

import ProductDetail from './ProductDetail';
import ProductRelated from './ProductRelated';
import ProductAngsuran from './ProductAngsuran';
import NotFound from '../../components/misc/NotFound';

class IndexProduct extends Component {
    componentWillMount() {
        this.props.getDataUnit(this.props.match.params);
    }
    // componentDidUpdate() {
    //     this.props.getDataUnit(this.props.match.params)
    // }
    
    render() {
        return !this.props.unit.isLoad && this.props.unit.unitDisplay.length > 0 ? (
            <>
                <main id="product" ref="main">
                    <ProductDetail data={this.props.unit.unitDisplay[0]} />
                    <hr/>
                    <ProductAngsuran groupdealer={this.props.unit.gdId} unit_id={this.props.unit.unitDisplay[0].id} />
                    <hr/>
                    <ProductRelated productId={this.props.unit.unitDisplay[0].id} category={this.props.unit.unitDisplay[0].master_unit_category_id} />
                </main>
            </>
        ) : 
        !this.props.unit.isLoad && this.props.unit.unitDisplay.length === 0 ? (
            <>
                <NotFound/>
            </>
        ) : (
            <>
            <div className="container-loading">
                <img style={{ width : '5%'}} alt=".." src={ require('../../assets/images/theme/loader.svg')}/>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    unit: state.unit
})

const mapDispatchToProps = (dispatch) => ({
    getDataUnit: (pageParams) => dispatch(getDataUnit(pageParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(IndexProduct)