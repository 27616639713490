import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col } from 'reactstrap';
import ListItem from '../home/ListItem'

class ProductRelated extends Component {
    render() {
        const { unit, gdId, gdName } = this.props.unit;
        let count = 0;
        const productShown = unit.filter((val, key) => {
            return val.master_unit_category_id === this.props.category && val.id !== this.props.productId && count++ < 5;
        })
        return (
            <div className="product-wrap product-related bg-white py-4">
                <Container className="container-lg">
                    <h4 className="blue-core">Related product</h4>
                    <div className="home-product-list">
                        <div className="product-list-wrap">
                            {
                                productShown.length > 0 ? productShown.map((val, key) => {
                                    return <ListItem
                                        key={key}
                                        id={val.id}
                                        image={val.default_picture}
                                        name={val.name}
                                        description={val.description}
                                        price={val.price}
                                        brand={val.brand}
                                        brandId={val.master_brand_id}
                                        gdId={gdId}
                                        gdName={gdName}
                                    />
                                }) : (
                                    <Col lg={12} className="text-center">
                                        <h5 className="p-4 text-center">No data available</h5>
                                    </Col>
                                )
                            }
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    unit: state.unit
})

export default connect(mapStateToProps, null)(ProductRelated)