import Config from "../../config/Config";

export const getBanner = () => {
    return (dispatch, getState) => {
        fetch(`${Config.URL + Config.URLs.BANNER}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS
        })
        .then(res => res.json())
        .then(resp => {
            dispatch({ type: 'SET_BANNER', banner: resp });
        })
    }
}

export const getBrand = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'RESET_UNIT' });
        fetch(`${Config.URL + Config.URLs.BRAND}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS,
            cors: true
        })
        .then(res => res.json())
        .then(resp => {
            var defView = 0;
            if (getState().filter.isset && getState().filter.brand !== 0) {
                defView = parseInt(getState().filter.brand);
            } else {
                defView = resp.filter( item => item.default_view === 1)[0].id
            }
            dispatch({ type: 'SET_BRAND', brand: resp });
            dispatch({ type: 'SET_FILTER', brand: parseInt(defView) });
            dispatch(getCategory(parseInt(defView)));
            dispatch(getLocation(parseInt(defView)));
        })
    }
}

export const onChooseBrand = (brand_id) => {
    return (dispatch, getState) => {
        dispatch({ type: 'RESET_UNIT' });
        dispatch({ type: 'SET_FILTER', brand: brand_id })
        dispatch(getCategory(brand_id))
        dispatch(getLocation(brand_id))
    }
}

export const getCategory = (brand_id) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_CATEGORY', category: [] });
        dispatch({ type: 'LOAD_CATEGORY' });
        fetch(`${Config.URL + Config.URLs.CATEGORY}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS,
            cors: true,
            body: JSON.stringify({brand: brand_id})
        })
        .then(res => res.json())
        .then(resp => {
            dispatch({ type: 'SET_CATEGORY', category: resp });
            dispatch({ type: 'SET_FILTER', category: [] })
        })
    }
}

export const getLocation = (brand_id) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOCATION', location: [] });
        dispatch({ type: 'LOAD_LOCATION' });
        fetch(`${Config.URL + Config.URLs.LOCATION}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS,
            cors: true,
            body: JSON.stringify({master_brand_id: brand_id})
        })
        .then(res => res.json())
        .then(resp => {
            getState().filter.isset && getState().filter.location !== 0 ? (
                dispatch({ type: 'SET_FILTER', location: parseInt(getState().filter.location) })
            ) : (
                dispatch({ type: 'SET_FILTER', location: resp.length > 0 ? parseInt(resp[0].id) : 0, isset: true })
            )
            dispatch({ type: 'SET_LOCATION', location: resp });
            dispatch(getUnit())
        })
    }
}

export const getUnit = (unit_id) => {
    return (dispatch, getState) => {
        var filter = getState().filter;
        dispatch({ type: 'RESET_UNIT', unit: [] });
        dispatch({ type: 'LOAD_UNIT' });
        fetch(`${Config.URL + Config.URLs.UNIT}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS,
            cors: true,
            body: JSON.stringify({
                filter : {
                    group_dealer_area_id: filter.location,
                    brand: filter.brand,
                    unit_category: filter.category.length > 0 ? filter.category : null
                }
            })
        })
        .then(res => res.json())
        .then(resp => {
            if (unit_id) {
                dispatch(fetchUnit(unit_id, resp))
            } else {
                dispatch({
                    type: 'SET_FILTER',
                    price_min: resp.min,
                    price_max: resp.max
                })
                dispatch({
                    type: 'SET_UNIT',
                    unit: resp.data,
                    gdId: resp.gdId,
                    gdName: resp.value,
                });
            }
        })
    }
}

export const getAngsuran = (params) => {
    return (dispatch, getState) => {
        dispatch({ type: 'RESET_ANGSURAN' });
        
        fetch(`${Config.URL + Config.URLs.ANGSURAN}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS,
            cors: true,
            body: JSON.stringify({data: params})
        })
        .then(res => res.json())
        .then(resp => {
            if ('top_Group' in resp.result[0]) {
                const { top_Group, master_dp_angsuran } = resp.result[0];
                dispatch({ type: 'SET_ANGSURAN', top_group: top_Group, angsuran: master_dp_angsuran });
            } else {
                dispatch({ type: 'SET_ANGSURAN' });
            }
        })
    }
}

export const filterChange = (name, value, eOpts) => {
    return (dispatch, getState) => {
        if (name ==='unit_category') {
            var category = getState().filter.category || [];
            if (eOpts.isChecked) {
                category.push(value)
            } else {
                for( var i = 0; i < category.length; i++){ 
                    if ( category[i] === value) {
                        category.splice(i, 1); 
                    }
                }
            }
            dispatch({ type: 'SET_FILTER', category: category });
            dispatch(getUnit())
        } else if (name === "group_dealer_area_id") {
            dispatch({ type: 'SET_FILTER', location: parseInt(value) });
            dispatch(getUnit())
        } else if (name === 'price_range') {
            var filtered = getState().unit.unit.filter(val => {
                return val.price >= value.min && val.price <= value.max;
            })
            dispatch({ type: 'SET_UNIT_FILTER', unitFiltered: filtered })
        }
    }
}

export const getDataUnit = (pageParams) => {
    return (dispatch, getState) => {
        dispatch({ type: 'RESET_UNIT', unit: [] });
        var { location, brand, unit } = pageParams;
        dispatch({
            isset: true,
            type: 'SET_FILTER',
            location: Config.FUNC.getSlugId(location),
            brand: Config.FUNC.getSlugId(brand),
            category: []
        });
        dispatch(getUnit(Config.FUNC.getSlugId(unit)));
    }
}

export const fetchUnit = (unit_id, data) => {
    return (dispatch, getState) => {
        var unit = data.data.filter(val => {
            return val.id === parseInt(unit_id);
        })
        dispatch({
            type: 'SET_UNIT',
            unit: data.data,
            unitDisplay: unit,
            gdId: data.gdId,
            gdName: data.value,
        });
    }
}

export const joinDealer = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: 'HOME_LOAD' });
        fetch(`${Config.URL + Config.URLs.JOIN}`, {
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(resp => {
            dispatch({ type: 'JOIN_DEALER', success: resp.success, msg: resp.data.message });
        }).catch(err => {
            dispatch({ type: 'JOIN_DEALER', success: false, msg: 'Failed to save.' });
        });
    }
}

export const getComboBrand = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'LOAD_COMBO_BRAND' });
        fetch(`${Config.URL + Config.URLs.BRAND}`, {
            method: "POST",
            headers : Config.AJAX.HEADERS,
            cors: true
        })
        .then(res => res.json())
        .then(resp => {        
            var data = [];   
            for (let i = 0; i < resp.length; i++) {
                const new_data = {
                    label : resp[i].display.toUpperCase(),
                    value : resp[i].id,
                    data  : resp[i]
                }
                data.push(new_data);   
            }
            dispatch({ type: 'SET_COMBO_BRAND', brand: data });
        })
    }
}

export const getComboUnit = (id_brand) => {
    return (dispatch, getState) => {
        var filter = getState().filter;
        dispatch({ type: 'LOAD_COMBO_UNIT' });
        fetch(`${Config.URL + Config.URLs.UNIT}`, {
            method  : "POST",
            headers : Config.AJAX.HEADERS,
            cors    : true,
            body    : JSON.stringify({
                filter : {
                    group_dealer_area_id    : filter.location,
                    brand                   : id_brand,
                }
            })
        })
        .then(res => res.json())
        .then(resp => {
            var data = [];   
            for (let i = 0; i < resp.data.length; i++) {
                const new_data = {
                    label : resp.data[i].name.toUpperCase(),
                    value : resp.data[i].id,
                    data  : resp.data[i]
                }
                data.push(new_data);   
            }
            dispatch({ type: 'SET_COMBO_UNIT', unit: data });
        })
    }
}